import React from 'react'
import {Navbar} from 'react-bootstrap';
const NavBar = () => {
  return (


    <div>
    <Navbar>
      <Navbar.Brand href="/">Jamie Malcolm</Navbar.Brand>
      <Navbar.Brand href="/blog">Blog</Navbar.Brand>
      <Navbar.Brand href="/about">About</Navbar.Brand>
      <Navbar.Brand href="/portfolio">Portfolio</Navbar.Brand>
    </Navbar>
      
    </div>
)
}

export default NavBar
