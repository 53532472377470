import React, { useContext } from 'react'
import { Accordion, AccordionContext, Card, useAccordionToggle } from 'react-bootstrap'
import ReadMore from '../read-more.svg'
import ReadLess from '../up-arrow.svg'

function ContexAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const switchImageOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  if (isCurrentEventKey) {
    return (<Card.Header onClick={switchImageOnClick}>{children} <img width="30px" height="30px" src={ReadLess} alt="read less"></img> </Card.Header>)
  } else {

    return (<Card.Header onClick={switchImageOnClick}>{children} <img width="30px" height="30px" src={ReadMore} alt="read more"></img> </Card.Header>)
  }
}

const BlogPage = () => {
  return (

    <div className="blogContainer">
      {/* <Accordion defaultActiveKey="0">
        <Card>
          <ContexAwareToggle eventKey="0">
            <h2>New Post</h2>
          </ContexAwareToggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              Content....
          </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion> */}

      <Accordion defaultActiveKey="0">
        <Card>
          <ContexAwareToggle eventKey="0">
            <h3>Tue 27 Apr 2021</h3>
            <h2>Progress</h2>
          </ContexAwareToggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <p>After a hard couple of weeks I’ve had multiple drafts for this next blog post.  I have been working hard on topics that I had discussed with my mentor <a href="https://www.linkedin.com/in/tbutterwith/">Thomas Butterwith</a>.  Tom is a Senior Developer at Sky Scanner and volunteers at Code First, an organisation dedicated to getting women into the tech industry.  Recently he has been helping me with topics that I was struggling with during the interview process. He also has his own personal site check that out <a href="https://butterwith.space/">here.</a></p>
              <p>I was, at first, overwhelmed by the content covered in some of the junior developer interviews I have been a part of.  However with some help from Tom and some hard work I feel I have made great progress and that I’m in a good place to continue interviewing.</p>
              <p>Clearly it all paid off as I am currently waiting for a formal offer from someone that should hopefully be coming through this week.  I can’t really say much more at this point but I’ll be able to update you soon on the details.</p>
              <p>Having an offer on the table is a great relief as I haven’t been employed since October last year.  It’s definitely a weight of the shoulders and has confirmed that I made the correct decision to take the leap and switch careers during this crazy time we are all living through.</p>
              <p>Im excited to see what this opportunity may bring me and for now I’m just going to keep my head down and keep working hard in all aspects of software development.  I have a few ideas for some projects and also I’ll be updating my site with some new features.  I’ve already added a portfolio section to the site so please check that out <a href="https://jfmalcolm.co.uk/portfolio">here</a> or click the link on the top of the page.</p>
              <p>Hopefully I will be able to give some more information on my next role soon! But for now I need to get back to work!</p>
              <p>Thanks for reading.</p>
              <h5>Jamie</h5>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <Accordion defaultActiveKey="null">
        <Card>
          <ContexAwareToggle eventKey="0">
            <h3>Fri 2 Apr 2021</h3>
            <h1>The Job Hunt...</h1>
          </ContexAwareToggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <p>It’s been a while since I last posted so I thought I’d better stick to my word and give an update about the big scary part of changing careers, the job hunt!</p>
              <p>My days recently feel like they have been rolling into one.  After my last post I did a showcase presentation to potential employers through CodeClan and no matter how confident you think you are nothing prepares you for standing up(virtually) in-front of people and trying to convince them you’ve done a good job on a project you assigned yourself.  It seemed to go rather well however as it has led to an informal meeting with an agency so fingers crossed.</p>
              <p>Apart from that I have been trying to keep myself as busy as possible with coding challenges and job applications.  I’ve sent out dozens of job applications and chances are I’ll hear from about 10% of them if I'm lucky.  This is a topic that has been covered before on many different platforms so I won’t go further into it than that, this just seems to be the way of the world and I’ve accepted that.</p>
              <p>After days of hearing nothing it was terrifying and exciting to have to put on that best version of myself and have an interview over zoom.  I think in my whole career in the hospitality industry I may have had one formal sit down interview, so the whole process is completely new to me.  So far I have only had some informal chats with employers and nothing has really moved onto another stage in the process, apart from one.  I was given a coding challenge and boy was it a challenge.  It was definitely a learning experience for me and made me work hard and look up things I thought(naively) that I knew.</p>
              <p>Being thrown in to the deep end is good and I feel that sometimes I work best under that kind of pressure but after somewhat struggling with the challenge I have decided to make a point of practicing these Algorithmic problems much more often.  Which, hopefully, will lay me in good stead as I progress further into my job search.</p>
              <p>For anyone else in a similar situation as myself I know there are plenty of different platforms for practicing interview style coding problems however I have found my favourite and its <a href="https://www.codesignal.com">CodeSignal.</a>  It’s a very intuitive UI with a huge range of tasks and learning tools.  You can solve problems in whatever programming language you like too, which for someone like me who isn’t quite sure yet on which language to really dive into, (whatever one will get me a job), is great.</p>
              <p>Well I’ve got challenges to solve and jobs to apply for so until next time, thanks for reading.</p>
              <p>Jamie </p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <Accordion defaultActiveKey="null">
        <Card>
          <ContexAwareToggle eventKey="0">
            <h3>Thu 18 Mar 20201</h3>
            <h1>From Chef to Coder.</h1>
          </ContexAwareToggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <p>My name is Jamie Malcolm. I have been a chef in the hospitality industry for 10 years, during the pandemic I was still in full-time employment all be it completely different from any normal working pattern in my career. The uncertainty of the future in the industry gave me a massive wake-up call and so, I began my journey into coding.</p>

              <p>Ok so disclaimer I am incredibly lucky in that I have two close friends already in the industry:<a href="https://www.linkedin.com/in/patrickclover/">Patrick Clover</a> and <a href="https://www.linkedin.com/in/josef-benassi-91736b172/">Josef Benassi</a>.  Patrick is the founder of Stampede and incredible company making it so simple for businesses to provide WiFi to their customers and actually gain from it.  He was kind enough to allow me to work with him after hours and give me an insight into how a SaaS company runs and how the development team works.  This was invaluable experience and I can’t thank him enough.  Joe has been there whenever I needed to ask him about anything code related he works for Zaizi in London.</p>

              <p>With my experience outside of the kitchen being little to none, I decided I had to gain some skills. I have always been interested in the tech industry and am amazed at how quickly it grows and adapts especially in times like these. With that in mind, I decided to journey into web development. It had been in my thoughts for a few years prior and I had looked into different university courses and shorter boot camps. As I was already in full-time employment I was hesitant and nervous to give up a wage and go into full-time education. The reality of the pandemic made it clear that it was a now or never decision. </p>



              <p>I began by trawling the Internet for any and all free resources I could find related to web development and coding (there’s a lot). After narrowing it down I had a few websites that for me and my experience level seemed ideal to begin learning.</p>

              <p>I used <a href="https://upskillcourses.com/courses">upskill.com.</a>It gave me access to a huge library of instructional videos and text all for free. I felt that I made some fast progress in understanding some main concepts although not feeling greatly confident in how to use different tools in chrome inspector or Amazon AWS. There was also a week or so when I could not access the website for an unknown reason and when this happened I moved over to free code camp. Much like upskill this was a free service providing very similar content. I found that the interface although appearing simple with old school fonts and plain styles it was much better and allowing you to track your progress and used and ide which for a beginner is a great help.</p>



              <p>Once I had gone through a fair portion of the course I started to get confused and stuck on a lot of the material. It was frustrating, I felt like quitting a few times but knew I had no choice but to just stick with it and try to find different ways of taking in the information. </p>

              <p>As I said before I had my friends in the industry and you better believe I used this to my advantage. They were incredibly supportive and helped me massively with any issues I was having, two minutes after reaching out we were in a google hangout going over the issues I was having with my online courses. After they had helped me get to the solution of my first problems they began to probe! Quizzing me on my course and how I was going about the whole self-teaching thing. And after explaining everything they gave me a tough truth to bear, this isn’t going to be easy and it’s going to take a long time!. They told me the importance of learning the absolute fundamentals of coding and web development and how this would in turn help me to understand much more complex material when I came to it. They pointed me in the direction they thought would be most appropriate for an aspiring coder!</p>

              <p> HTML
              CSS
JavaScript</p>
              <p>With this in mind, I went back to the internet which seems to be your best friend as a web developer. I found a few podcasts and more materials to help me including <a href="https://syntax.fm/">Syntax</a> and a few courses by one of the speakers on the podcast <a href="https://wesbos.com/">Wes Bos.</a> I spent all of my free time after work studying and going through the online materials I had found and I have to say, I loved it! It was decision time, continuing with self-teaching and progressing slowly, or, bite the bullet and go with a boot camp to fast track my progress.</p>
              <p>I chose to go with <a href="https://codeclan.com">CodeClan</a> in Edinburgh. I had heard so much about them in previous years but as I mentioned before I didn’t feel like I was in the position to do it. I had intentions of posting this early on or even before starting my course however I was completely consumed by the content of the course and felt I needed to focus all of my energy on that.</p>
              <p>I graduate from my course tomorrow and it's astonishing how far I’ve come in the 16-Week course. I've had solo projects, a Group project, and worked on an app to find and log covid safe spaces in and around Edinburgh for friends to get outside in these strange times! I will continue to write as I progress into what is my new future: Sitting in front of a computer and burning my eyes red! - that one is for Joe.</p>
              <p>My journey is just beginning and it’s all excitement and anticipation for me just now. In the coming weeks, I will be posting more of my progress and what I find difficult and enjoy about the job hunt!</p>
              <p>Thanks for reading.</p>

              <h5>Jamie</h5>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
    // <div className="blogPH">
    //   {/* <h2>I'm currently thinking up something interesting to tell you...</h2> */}

    //   <div>
    //     <ul>
    //       <li className="blogPost">

    //         <h3>Thu 18 Mar 20201</h3>
    //         <h1>From chef to coder.</h1>

    //         <p>My name is Jamie Malcolm. I have been a chef in the hospitality industry for 10 years, during the pandemic I was still in full-time employment all be it completely different from any normal working pattern in my career. The uncertainty of the future in the industry gave me a massive wake-up call and so, I began my journey into coding.</p>

    //         <p>Ok so disclaimer I am incredibly lucky in that I have two close friends already in the industry:<a href="https://www.linkedin.com/in/patrickclover/">Patrick Clover</a> and <a href="https://www.linkedin.com/in/josef-benassi-91736b172/">Josef Benassi</a>.  Patrick is the founder of Stampede and incredible company making it so simple for businesses to provide WiFi to their customers and actually gain from it.  He was kind enough to allow me to work with him after hours and give me an insight into how a SaaS company runs and how the development team works.  This was invaluable experience and I can’t thank him enough.  Joe has been there whenever I needed to ask him about anything code related he works for Zaizi in London.</p>

    //         <p>With my experience outside of the kitchen being little to none, I decided I had to gain some skills. I have always been interested in the tech industry and am amazed at how quickly it grows and adapts especially in times like these. With that in mind, I decided to journey into web development. It had been in my thoughts for a few years prior and I had looked into different university courses and shorter boot camps. As I was already in full-time employment I was hesitant and nervous to give up a wage and go into full-time education. The reality of the pandemic made it clear that it was a now or never decision. </p>



    //         <p>I began by trawling the Internet for any and all free resources I could find related to web development and coding (there’s a lot). After narrowing it down I had a few websites that for me and my experience level seemed ideal to begin learning.</p>

    //         <p>I used <a href="https://upskillcourses.com/courses">upskill.com.</a>It gave me access to a huge library of instructional videos and text all for free. I felt that I made some fast progress in understanding some main concepts although not feeling greatly confident in how to use different tools in chrome inspector or Amazon AWS. There was also a week or so when I could not access the website for an unknown reason and when this happened I moved over to free code camp. Much like upskill this was a free service providing very similar content. I found that the interface although appearing simple with old school fonts and plain styles it was much better and allowing you to track your progress and used and ide which for a beginner is a great help.</p>



    //         <p>Once I had gone through a fair portion of the course I started to get confused and stuck on a lot of the material. It was frustrating, I felt like quitting a few times but knew I had no choice but to just stick with it and try to find different ways of taking in the information. </p>

    //         <p>As I said before I had my friends in the industry and you better believe I used this to my advantage. They were incredibly supportive and helped me massively with any issues I was having, two minutes after reaching out we were in a google hangout going over the issues I was having with my online courses. After they had helped me get to the solution of my first problems they began to probe! Quizzing me on my course and how I was going about the whole self-teaching thing. And after explaining everything they gave me a tough truth to bear, this isn’t going to be easy and it’s going to take a long time!. They told me the importance of learning the absolute fundamentals of coding and web development and how this would in turn help me to understand much more complex material when I came to it. They pointed me in the direction they thought would be most appropriate for an aspiring coder!</p>

    //         <p> HTML
    //         CSS
    //        JavaScript</p>
    //         <p>With this in mind, I went back to the internet which seems to be your best friend as a web developer. I found a few podcasts and more materials to help me including <a href="https://syntax.fm/">Syntax</a> and a few courses by one of the speakers on the podcast <a href="https://wesbos.com/">Wes Bos.</a> I spent all of my free time after work studying and going through the online materials I had found and I have to say, I loved it! It was decision time, continuing with self-teaching and progressing slowly, or, bite the bullet and go with a boot camp to fast track my progress.</p>
    //         <p>I chose to go with <a href="https://codeclan.com">CodeClan</a> in Edinburgh. I had heard so much about them in previous years but as I mentioned before I didn’t feel like I was in the position to do it. I had intentions of posting this early on or even before starting my course however I was completely consumed by the content of the course and felt I needed to focus all of my energy on that.</p>
    //         <p>I graduate from my course tomorrow and it's astonishing how far I’ve come in the 16-Week course. I've had solo projects, a Group project, and worked on an app to find and log covid safe spaces in and around Edinburgh for friends to get outside in these strange times! I will continue to write as I progress into what is my new future: Sitting in front of a computer and burning my eyes red! - that one is for Joe.</p>
    //         <p>My journey is just beginning and it’s all excitement and anticipation for me just now. In the coming weeks, I will be posting more of my progress and what I find difficult and enjoy about the job hunt!</p>
    //         <p>Thanks for reading.</p>

    //         <h5>Jamie</h5>
    //       </li>
    //     </ul>
    //   </div>
    // </div>
  )
}

export default BlogPage
