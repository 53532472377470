import React, { useState } from 'react'
import { Container, Fade, Image } from 'react-bootstrap';
import GithubLogo from '../github.svg';
import LinkedinLogo from '../linkedin.svg';
import GmailLogo from '../gmail.svg';
import Me from '../me.jpeg';
import Roll from 'react-reveal/Roll';
import Slide from 'react-reveal/Slide';
import Rotate from 'react-reveal/Rotate';
import Spin from 'react-reveal/Spin';

const HomePage = () => {

  const [imgOpen, setImgOpen] = useState(false);
  const [counter, setCounter] = useState(0);

  const handleFaceClick = () => {
    setCounter(counter + 1);
    setImgOpen(true)
  }

  return (
    <>
      <Container>
        <Slide left cascade>
          <div className="hp-title">
            <h1>Jamie Malcolm</h1>
            <h2>Software Developer</h2>
          </div>
        </Slide>

        <Roll bottom>
          <Spin spy={counter}>

            <div className="me">
              <Image
                onClick={() => handleFaceClick()}
                aria-controls="intro-text"
                aria-expanded={imgOpen}
                src={Me} alt="me" roundedCircle />
              <Fade in={imgOpen}>
                <div className="picture-text" id="intro-text">
                  Hey! Stop clicking on my face and read my blog.
              </div>
              </Fade>
            </div>
          </Spin>
        </Roll>
        <Rotate bottom left cascade>
          <div className="links">
            <ul>
              <li>
                <a href="https://github.com/jamiemalcolm"><img className="logos" src={GithubLogo} alt="Github Logo" /> github.com/jamiemalcolm</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/jamie-malcolm/"><img className="logos" src={LinkedinLogo} alt="Linkedin Logo" /> linkedin.com/jamie-malcolm</a>
              </li>
              <li>
                <a href="mailto:jmalcolm281@gmail.com"><img className="logos" src={GmailLogo} alt="Gmail Logo" /> jmalcolm281@gmail.com</a>
              </li>
            </ul>
          </div>
        </Rotate>
      </Container>
    </>
  )
}

export default HomePage