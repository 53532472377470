import React from 'react'
import { Card, Button, CardDeck } from 'react-bootstrap';
import Flask from '../assets/flaskLogo.png'
import Java from '../assets/javaLogo.png'
import JavaScript from '../assets/javaScriptLogo.png'
import Python from '../assets/pythonLogo.png'
import Postgresql from '../assets/postgresqlLogo.png'
import Vue from '../assets/vueJsLogo.png'
import ReactLogo from '../assets/reactLogo.png'
import MongoDb from '../assets/mongodbLogo.png'
import Spring from '../assets/springLogo.png'
import MuiLogo from '../assets/muiLogo.png'
import STHP from '../assets/SpendingTrackerHomePage.png'
import EAHP from '../assets/EducationAppHomePage.png'
import OSHP from '../assets/openSpacesHomePage.png'

const PortfolioPage = () => {
    return (

        <div className='portfolio-container'>

        <CardDeck>
            <Card style={{ width: '300px', border: 'none', backgroundColor: '#F9F9F9' }}>
                <Card.Img variant="top" src={STHP} />
                <Card.Body>
                    <Card.Title>Spending Tracker</Card.Title>
                    <Card.Text>
                        <img className='logos' src={Python} alt="Python3 Logo" />
                        <img className='logos' src={Postgresql} alt="PostgreSQL Logo" />
                        <img className='logos' src={Flask} alt="Flask Logo" />
                        <br />
      A solo project created in Week-5 of CodeClan software developer course to allow a user to add, edit and remove Merchants, Categories and Transactions. Built using Python3, PsycoPG, Flask and using a PostgreSQL database.
    </Card.Text>
                    <Button size='sm' href="https://github.com/jamiemalcolm/spending_tracker_project" variant="secondary">View on GitHub</Button>
                </Card.Body>
            </Card>
            <Card style={{ width: '300px', border: 'none', backgroundColor: '#F9F9F9' }}>
                <Card.Img variant="top" src={EAHP} />
                <Card.Body>
                    <Card.Title>Education App</Card.Title>
                    <Card.Text>
                        <img className='logos' src={JavaScript} alt=" Logo" />
                        <img className='logos' src={Vue} alt=" Logo" />

                        <br />
        A Group Project in Week-8 of CodeClan software developer course with a brief of using an external API to provide Educational material on a chosen subject to people of various ages. Built using JavaScript and VueJS.
    </Card.Text>
                    <Button size='sm' href="https://github.com/jamiemalcolm/JS-Project-Week8" variant="secondary">View on GitHub</Button>
                </Card.Body>
            </Card>
            <Card style={{ width: '300px', border: 'none', backgroundColor: '#F9F9F9' }}>
                <Card.Img variant="top" src={OSHP} />
                <Card.Body>
                    <Card.Title>Open Spaces</Card.Title>
                    <Card.Text>
                        <img className='logos' src={JavaScript} alt="JavaScript Logo" />
                        <img className='logos' src={ReactLogo} alt="React Logo" />
                        <img className='logos' src={Java} alt="Java Logo" />
                        <img className='logos' src={Spring} alt="Java Spring Boot Logo" />
                        <img className='logos' src={MongoDb} alt="MongoDB Logo" />
                        <img className='logos' src={MuiLogo} alt="Material UI Logo" />
                        <br />
      A paired project created in Week-14 of CodeClan software developer course to highlight covid-safe Spaces to meet friends. Built with a React front-end styled with MaterialUi and a Java back-end with a MongoDB database.
    </Card.Text>
                    <Button size='sm' href='https://github.com/jamiemalcolm/OpenSpacesApp' variant="secondary">View on GitHub</Button>
                </Card.Body>
            </Card>
        </CardDeck>
        </div>
    )
}

export default PortfolioPage
