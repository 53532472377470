import React from 'react'

const ErrorPage = () => {
  return (
    <div>
      <h2>Ooops! dont know how you got here, head back to the home page and have another try!</h2>
    </div>
  )
}

export default ErrorPage