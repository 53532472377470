import React from 'react'

const AboutPage = () => {
  return (
    <div className="aboutMe">
      <h2>From Chef to Coder</h2>
      <p>
        For the past 10 years i've worked in the hospitality industry.  From small pub to Sainsbry's Bank Catering, to 5 Star Hotel in the north of France and back again.  I loved challenging myself.
        I eventually found that I had acheived all I had really set out to as a Chef.  My days became less exciting and I had an itch for something new.
        As the pandemic hit my work continued to provide a service but it was completely scalled back and I found myself in a state of flux(great song).</p>

        <p>Enter Code.</p>

        <p>I began to teach myself some basic HTML, CSS and Very, Very basic JavaScript.  This fuelled my ambition and I decided that I had to commit more time to this.
        Thats when I found CodeClan. if youd like more information on them its here <a href="https://codeclan.com">codeclan.com.</a>  Basically they are wizzards.
        They take someone like myself, shove them infront of a macBook and 16 weeks later they have become total nerds obsessed with Software Development.</p>

        <p>I'm at a stage now where i feel somewhat confident in my abilities as a coder.  But, more importantly, I feel so much more confident that even if i have never seen a single line of code in some new programming language, in a week or so ill give you an app using it. 
        Thats a little bit about me. if you have any questions or would like to know more about me or hey, maybe even offer me a position get in touch! There is a link to my email on my home page!
      </p>
    </div>
  )
}

export default AboutPage