import React from 'react';
import './App.css'; import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import BlogPage from './components/BlogPage';
import ErrorPage from './components/ErrorPage';
import NavBar from './components/NavBar';
import ScrollToTop from 'react-scroll-to-top';
import PortfolioPage from './components/PortfolioPage';


function App() {
  return (
    <div className="App">
      <Router>
        <>
          <ScrollToTop smooth/>
          <NavBar />
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/about" component={AboutPage} />
            <Route path="/blog" component={BlogPage} />
            <Route path="/portfolio" component={PortfolioPage} />
            <Route component={ErrorPage} />
          </Switch>
        </>
      </Router>
      
    </div>
  );
}

export default App;
